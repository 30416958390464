@charset "UTF-8";

/*===============================================
  AdminDesigns Custom Icon Library
================================================*/

/* Load font library */
@font-face {
  font-family: "AdminDesigns";
  src:url("../../fonts/admindesigns/admindesigns.eot");
  src:url("../../fonts/admindesigns/admindesigns.eot?#iefix") format("embedded-opentype"),
      url("../../fonts/admindesigns/admindesigns.woff") format("woff"),
      url("../../fonts/admindesigns/admindesigns.ttf") format("truetype"),
      url("../../fonts/admindesigns/admindesigns.svg#admindesigns") format("svg");
  font-weight: normal;
  font-style: normal;

}

/* Font base class */
.ad {
  position: relative;
  display: inline-block;
  font: normal normal normal 16px/1 AdminDesigns;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

/* Icon classes */
.ad-wand {
  top: 1px;
}
.ad-wand:before {
  content: "\e010";
}
.ad-lines:before {
  content: "\e600";
}
.ad-radio-tower:before {
  content: "\f030";
}
.ad-ruby:before {
  content: "\f047";
}
.ad-screen-full:before {
  content: "\f066";
}
