/*@import '~bootstrap';*/
/*@import '~font-awesome';*/

@import './template/open_sans_font.css';
@import './template/admindesigns.css';
@import './template/core.css';
@import './template/theme.css';
@import './template/admin-forms.css';
@import './template/dataTables.bootstrap.css';
@import './template/dataTables.editor.css';
@import './template/footable.core.min.css';
@import './template/summernote.min.css';