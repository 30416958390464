
/*
 * Editor Plugin CSS 
 * Bootstrap Overrides
 */

 div.DTE_Field:after {
  display: block;
  content: ".";
  height: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}

div.DTE_Inline div.DTE_Field {
  width: 100%;
}
div.DTE_Inline div.DTE_Field > div {
  width: 100%;
  padding: 0;
}
div.DTE_Inline div.DTE_Field label {
  display: none;
}
div.DTE_Inline div.DTE_Field input {
  width: 100%;
  color: black;
}
div.DTE_Inline div.DTE_Field div.help-block {
  display: none;
}

div.DTE_Bubble {
  position: absolute;
  z-index: 11;
  margin-top: -6px;
  opacity: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner {
  position: absolute;
  bottom: 0;
  border: 1px solid black;
  width: 300px;
  margin-left: -150px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 0 0 0.5em 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table {
  display: table;
  width: 100%;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form {
  display: table-cell;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content {
  padding: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field {
  position: relative;
  zoom: 1;
  margin-bottom: 0.5em;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field:last-child {
  margin-bottom: 0;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field label,
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field > div {
  width: 100%;
  float: none;
  clear: both;
  text-align: left;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table > form div.DTE_Form_Content div.DTE_Field label {
  padding-bottom: 4px;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Table div.DTE_Form_Buttons {
  display: table-cell;
  vertical-align: bottom;
  padding: 0 1em 0.75em 0.75em;
  width: 1%;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  font-size: 14px;
  width: 100%;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Form_Error {
  float: none;
  display: none;
  padding: 0;
  margin-bottom: 0.5em;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close {
  position: absolute;
  top: -11px;
  right: -11px;
  width: 22px;
  height: 22px;
  border: 2px solid white;
  background-color: black;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  *cursor: hand;
  z-index: 12;
  box-shadow: 2px 2px 6px #111;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close:after {
  content: '\00d7';
  color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close:hover {
  background-color: #092079;
  box-shadow: 2px 2px 9px #111;
}
div.DTE_Bubble div.DTE_Bubble_Liner div.DTE_Bubble_Close:after {
  margin-top: -2px;
  display: block;
}
div.DTE_Bubble div.DTE_Bubble_Triangle {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -6px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

div.DTE_Bubble_Background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.05);
}
